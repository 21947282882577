



































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import {
  SfCarousel,
  SfButton,
} from '@storefront-ui/vue';
import VLazyImage from "v-lazy-image/v2";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import CouponCard from "./CouponCard.vue";
import { useCoupon } from "~/composables";
import CouponDetail from "./CouponDetail.vue";

export default defineComponent({
  name: 'CouponHomePage',
  components: {
    VLazyImage,
    SfCarousel,
    SfButton,
    VueSlickCarousel,
    CouponCard,
    CouponDetail,
  },
  setup() {
    const coupons = ref([]);
    const {
      getCouponList,
      keptCoupon: useKeptCoupon,
      error,
    } = useCoupon();
    const isCPDModalOpen = ref(false);
    const couponDetail = ref({});

    const closeCPDModal = () => {
      if (isCPDModalOpen.value === true) {
        isCPDModalOpen.value = false;
        couponDetail.value = {};
      }
    };

    const onCPDModalOpen = (coupon: any) => {
      if (isCPDModalOpen.value === false) {
        setTimeout(async () => {
          isCPDModalOpen.value = true;
          couponDetail.value = coupon;
        }, 100);
      }
    };

    const queryBrands = async () => {
      const getCoupons = await getCouponList({
        limit: 9,
        page: 1,
        type: 'coupon_list',
        searchName: '',
      });
      coupons.value = getCoupons.items;
    }

    const { fetch, fetchState } = useFetch(() => {
      queryBrands();
    });
    fetch();

    const keptCoupon = async (rule_id: number, coupon_id: number) => {
      const getCoupons = await useKeptCoupon({
        rule_id,
        coupon_id,
        limit: 9,
        page: 1,
        type: 'coupon_list',
        searchName: '',
      });
      if (!error.value.keptCoupon) {
        coupons.value = getCoupons.items;
      }
    }

    onMounted(() => {
      queryBrands();
    });

    return {
      coupons,
      keptCoupon,
      isCPDModalOpen,
      closeCPDModal,
      onCPDModalOpen,
      couponDetail,
    };
  },
  data() {
    return {
      responsive: [
        {
          breakpoint: 1023,
          settings: "unslick",
        },
      ],
    };
  },
});
